import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const AboutText = styled.h3`
  font-size: 1.4rem;

`;

const SecondPage = () => (
  <Layout>
    <SEO title="About Me" />
  <section>
  <h2>About me</h2>
  <AboutText>I've been working in software industry for 10+ years as RD, QA and Developer, having solid experience in full-stack web development, test automation, cloud service and CI/CD.</AboutText>
  <AboutText>Lately, I use <i>javascript/python</i> a lot since they are now widely supported by many tools and platforms. </AboutText>
  <AboutText>In the past, I've been working with different languages like JAVA, C#, C/C++ on different platforms for different purposes.</AboutText>
  <AboutText>And my job responsibilities helps me get exposure to various technologies, ex: selenium webdriver, Jenkins, docker, postgres, mongoDB, react, angular, protractor, etc. </AboutText>
  <AboutText>Every language is created for its specific purpose, and so is any technology stack. </AboutText>
  <AboutText>I am so grateful to have chances to work on them and to learn that making decision to choose the right tech stack prior to development is crucial.</AboutText>
  <AboutText>Even though, we always learn something from failure...But any improvement does count! Any lession learnt is helpful!</AboutText>
  <AboutText>Hopefully, I get to share more of my learning experience here. :)</AboutText>
  </section>
  <br></br>


  </Layout>
)

export default SecondPage
